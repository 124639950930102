
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditClientClaimDetails from "@/components/individual/EditClientClaimDetails.vue";

export default defineComponent({
  name: "broker-motor-insurance-claims-edit",
  components: { EditClientClaimDetails },
  props: ["publicId"],
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit", [
        "Claims",
        "Report Claims",
        "Motor Insurance Claim"
      ]);
    });
  }
});
